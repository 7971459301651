import { render, staticRenderFns } from "./LabelSearch.vue?vue&type=template&id=085a3633&scoped=true&"
import script from "./LabelSearch.vue?vue&type=script&lang=js&"
export * from "./LabelSearch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "085a3633",
  null
  
)

export default component.exports