<template>
  <div :id="'labels-dropdown-202103211029-'"
       class="labels-dropdown my-auto"
  >
    <div role="button"
         class="dropdown-button"
         @click="toggleLabelsDropdown"
    >
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'labels-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.878 0h2.734c.31 0 .607.123.826.343l3.22 3.218c.456.457.456 1.2 0 1.657l-2.44 2.44c-.458.456-1.2.456-1.657 0L.343 4.437A1.167 1.167 0 010 3.612V.878C0 .393.393 0 .878 0zm1.17 2.633a.585.585 0 10.002-1.169.585.585 0 00-.002 1.17z" fill="#2A2D52"/></svg>
      <span class="mx-2">
        Label
      </span>
      <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.003.853A.847.847 0 0011.546.1a.86.86 0 00-.946.099L6.016 4.006 1.419.19A.857.857 0 00.213.318a.849.849 0 00.128 1.24l5.136 4.25a.859.859 0 001.086 0l5.132-4.25a.85.85 0 00.308-.705z" fill="#2A2D52" fill-opacity=".6"/></svg>
    </div>
    <transition name="animate-down">
      <div v-if="showLabelsDropdown"
           class="label-dropdown"
           v-click-outside="closeLabelsDropdown"
      >
        <div class="input-container">
          <input type="search"
                 v-model="labelFilterSearch"
                 :placeholder="staticText.searchPlaceholderText"
                 class="input"
          >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.842 13.684a6.801 6.801 0 004.189-1.444L14.79 16 16 14.79l-3.76-3.759a6.8 6.8 0 001.445-4.189A6.85 6.85 0 006.842 0 6.85 6.85 0 000 6.842a6.85 6.85 0 006.842 6.842zm0-11.973a5.137 5.137 0 015.132 5.131 5.137 5.137 0 01-5.132 5.131 5.137 5.137 0 01-5.131-5.131 5.137 5.137 0 015.131-5.131z" fill="#7B7979"/></svg>
        </div>
        <div class="labels-container">
          <div v-for="(tag, index) in filteredTags"
               :key="index"
               class="label"
          >
            <label class="checkbox-label">
              <input :id="'checkbox-' + index"
                     type="checkbox"
                     v-model="selectedLabels[tag]"
                     @change="toggleSelectedLabels(tag)"
              >
              <span class="checkmark"></span>
            </label>
            <label class="mb-0"
                   :for="tag"
            >
              {{ getTransformedLabel(tag) }}
            </label>
          </div>
        </div>
        <div
          id="apply-btn-2022999"
          role="button"
          class="apply"
          @click="applyFilter"
        >
          {{ staticText.applyLabel }}
        </div>
      </div>
    </transition>
  </div>

</template>

<script>
import vClickOutside from "v-click-outside"

export default {
  name: "LabelSearch",
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      labelFilterSearch: "",
      showLabelsDropdown: false,
      selectedLabels: [],
      staticTextDefault: {
        applyLabel: "Apply",
        searchPlaceholderText: "Search Labels"
      }
    }
  },
  props: {
    tags: {
      required: false,
      type: Array,
      default: null
    },
    systemProtectedTags: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    filteredTags () {
      const transformedSearchValue = new RegExp(this.labelFilterSearch, "i")
      /**
      provided tags prop could be an array of just tag names or an array of tag objects.
      if it is an array of tag objects, we convert this to an array of tag names.
       - returns: tag names array filtered by user
      * */
      const tagsArray = this.tags.map(tag => tag.name ? tag.name : tag)
      return tagsArray.filter(name => transformedSearchValue.test(this.transformLabel(name)))
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    toggleLabelsDropdown () {
      this.showLabelsDropdown = !this.showLabelsDropdown
      this.labelFilterSearch = ""
    },
    closeLabelsDropdown () {
      this.showLabelsDropdown = false
    },
    transformLabel (label) {
      // The labels returned have parent/child structure
      // we split this structure and extract out the parent
      return label.split("/").pop()
    },
    applyFilter () {
      this.showLabelsDropdown = false
      this.$emit("filter", this.selectedLabels)
    },
    toggleSelectedLabels (name) {
      if (this.selectedLabels.includes(name)) {
        this.selectedLabels.splice(this.selectedLabels.indexOf(name), 1)
        return
      }
      this.selectedLabels.push(name)
    },
    getTransformedLabel (label) {
      return !this.systemProtectedTags ? this.transformLabel(label) : this.toPascalCase(this.transformLabel(label))
    }
  }
}
</script>

<style scoped>

</style>
